<template>
  <div>
    <b-overlay
      id="overlay-background"
      v-if="!hasLoaded"
      fixed
      no-wrap
      variant="light"
      opacity="0.85"
      blur="5px"
    >
    </b-overlay>
    <div v-else>
      <div class="row custom_error" v-if="error">
        <p style="color:red;">{{ error }} </p>
      </div>
      <creazione-solleciti v-if="!error" :sollecito="template"></creazione-solleciti>
    </div>
  </div>
</template>

<script>
import CreazioneSolleciti from './CreazioneSolleciti'
import TemplateService from 'src/services/TemplateService'
export default {
  data() {
    return {
      error: null,
      hasLoaded: false,
      template: null
    }
  },
  components: {
    CreazioneSolleciti
  },
  async mounted() {
    try {
      const template = await TemplateService.getTemplate(this.$route.params.id)
      this.template = template.data
    } catch(err) {
      console.log('err', err)
      this.error = 'Errore durante la get del sollecito!'
    } finally {
      this.hasLoaded = true
    }
  }
}
</script>

<style>

</style>